











import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import MnemonicDisplay from "@/components/misc/MnemonicDisplay.vue";
import CopyMnemonic from "@/components/misc/CopyMnemonic.vue";
import CopyText from "@/components/misc/CopyText.vue";
import MnemonicPhrase from "@/js/wallets/MnemonicPhrase";

@Component({
  components: {
    Modal,
    MnemonicDisplay,
    CopyMnemonic,
    CopyText,
  },
})
export default class MnemonicPhraseModal extends Vue {
  @Prop({ default: "" }) phrase!: MnemonicPhrase;

  open(): void {
    let modal = this.$refs.modal as Modal;
    modal.open();
  }
}
