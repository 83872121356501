
































import "reflect-metadata";
import { Vue, Component, Watch } from "vue-property-decorator";

import Spinner from "@/components/misc/Spinner.vue";
import Modal from "./Modal.vue";
import { ILedgerBlockMessage } from "../../store/modules/ledger/types";
import { LEDGER_EXCHANGE_TIMEOUT } from "../../store/modules/ledger/types";

@Component({
  components: {
    Modal,
    Spinner,
  },
})
export default class LedgerBlock extends Vue {
  duration: number = LEDGER_EXCHANGE_TIMEOUT / 1000;
  intervalId: ReturnType<typeof setTimeout> | null = null;

  open() {
    // @ts-ignore
    this.$refs.modal.open();
    this.startTimer();
  }
  close() {
    // @ts-ignore
    this.$refs.modal.close();
    this.stopTimer();
  }

  get title(): string {
    return this.$store.state.Ledger.title;
  }

  get info(): string {
    return this.$store.state.Ledger.info;
  }

  get messages(): Array<ILedgerBlockMessage> {
    return this.$store.state.Ledger.messages;
  }

  get isActive(): boolean {
    return this.$store.state.Ledger.isBlock;
  }

  get isPrompt(): boolean {
    return this.$store.state.Ledger.isPrompt;
  }

  @Watch("isActive", { immediate: true })
  onActive(val: boolean): void {
    if (!this.$refs.modal) return;
    if (val) {
      this.open();
    } else {
      this.close();
    }
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      this.duration -= 1;
      if (this.duration <= 0) {
        this.duration = 0;
      }
    }, 1000);
  }

  stopTimer() {
    this.duration = LEDGER_EXCHANGE_TIMEOUT / 1000;
    clearInterval(this.intervalId!);
  }
}
