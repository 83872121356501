







import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import NftPayloadView from "@/components/misc/NftPayloadView/NftPayloadView.vue";
import { PayloadBase } from "avalanche/dist/utils";
@Component({
  components: { NftPayloadView, Modal },
})
export default class NFTViewModal extends Vue {
  @Prop() payload!: PayloadBase;

  $refs!: {
    modal: Modal;
  };

  open() {
    this.$refs.modal.open();
  }
}
