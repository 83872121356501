






































import { Vue, Component, Prop } from "vue-property-decorator";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import { LedgerWallet } from "@/js/wallets/LedgerWallet";
import { DerivationListBalanceDict } from "@/components/modals/HdDerivationList/types";
import HdDerivationListRow from "@/components/modals/HdDerivationList/HdDerivationListRow.vue";
import HdEmptyAddressRow from "@/components/modals/HdDerivationList/HdEmptyAddressRow.vue";
import { HdHelper } from "@/js/HdHelper";
@Component({
  components: { HdEmptyAddressRow, HdDerivationListRow },
})
export default class HdChainTable extends Vue {
  @Prop() wallet!: MnemonicWallet | LedgerWallet;
  @Prop() addresses!: string[];
  @Prop() balanceDict!: DerivationListBalanceDict[];
  @Prop() path!: number;
  @Prop() helper!: HdHelper;

  addressesFuture: string[] = [];

  showMore() {
    this.addFutureAddress(10);
  }

  addFutureAddress(amt: number) {
    let indexNow = this.addresses.length + this.addressesFuture.length;
    let addrs = [];
    for (var i = indexNow; i < indexNow + amt; i++) {
      let addr = this.helper.getAddressForIndex(i);
      addrs.push(addr);
    }
    this.addressesFuture.push(...addrs);
  }
}
