




































import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import {
  KeyPair as AVMKeyPair,
  UTXOSet as AVMUTXOSet,
} from "avalanche/dist/apis/avm";
import { UTXOSet as PlatformUTXOSet } from "avalanche/dist/apis/platformvm";
import { ava, bintools } from "@/AVA";
import Big from "big.js";
import AvaAsset from "@/js/AvaAsset";
import { DerivationListBalanceDict } from "@/components/modals/HdDerivationList/types";
import { LedgerWallet } from "../../../js/wallets/LedgerWallet";
import { bnToBig } from "@/helpers/helper";
import { BN } from "avalanche";
import HdChainTable from "@/components/modals/HdDerivationList/HdChainTable.vue";

@Component({
  components: {
    HdChainTable,
  },
})
export default class HDDerivationList extends Vue {
  @Prop() wallet!: MnemonicWallet | LedgerWallet;

  addrsExternal: string[] = [];
  addrsInternal: string[] = [];
  addrsPlatform: string[] = [];

  @Watch("wallet.internalHelper.utxoSet", { immediate: true })
  onInternalUtxoChange() {
    this.addrsInternal = this.wallet.internalHelper.getAllDerivedAddresses();
  }

  @Watch("wallet.externalHelper.utxoSet", { immediate: true })
  onExternalUtxoChange() {
    this.addrsExternal = this.wallet.externalHelper.getAllDerivedAddresses();
  }

  @Watch("wallet.platformHelper.utxoSet", { immediate: true })
  onPlatformUtxoChange() {
    this.addrsPlatform = this.wallet.platformHelper.getAllDerivedAddresses();
  }

  get internalHelper() {
    return this.wallet.internalHelper;
  }
  get externalHelper() {
    return this.wallet.externalHelper;
  }
  get platformHelper() {
    return this.wallet.platformHelper;
  }

  get assetsDict() {
    return this.$store.state.Assets.assetsDict;
  }

  utxoSetToBalanceDict(
    set: AVMUTXOSet | PlatformUTXOSet,
    addrs: string[]
  ): DerivationListBalanceDict[] {
    let assets: AvaAsset[] = this.$store.state.Assets.assets;

    let denoms: number[] = assets.map((asset) => {
      return asset.denomination;
    });
    let assetIds: string[] = this.$store.getters["Assets/assetIds"];

    let res = [];
    for (var i = 0; i < addrs.length; i++) {
      let balDict: DerivationListBalanceDict = {};
      let addrBuff = bintools.stringToAddress(addrs[i]);
      assetIds.forEach((assetId, index) => {
        let bal: BN = set.getBalance([addrBuff], assetId);

        if (!bal.isZero()) {
          let balBig = bnToBig(bal, denoms[index]);
          balDict[assetId] = balBig;
        }
      });
      res.push(balDict);
    }
    return res;
  }

  get keyBalancesExternal(): DerivationListBalanceDict[] {
    let wallet = this.wallet;
    let utxoSet = wallet.externalHelper.utxoSet as AVMUTXOSet;
    let addrs = this.addrsExternal;

    return this.utxoSetToBalanceDict(utxoSet, addrs);
  }

  get keyBalancesInternal(): DerivationListBalanceDict[] {
    let wallet = this.wallet;
    let utxoSet = wallet.internalHelper.utxoSet;
    let addrs = this.addrsInternal;
    return this.utxoSetToBalanceDict(utxoSet, addrs);
  }

  get keyBalancesPlatform(): DerivationListBalanceDict[] {
    let wallet = this.wallet;
    let utxoSet = wallet.platformHelper.utxoSet;
    let addrs = this.addrsPlatform;
    return this.utxoSetToBalanceDict(utxoSet, addrs);
  }
}
