























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import CopyText from "@/components/misc/CopyText.vue";
import ExportWallet from "@/components/wallet/manage/ExportWallet.vue";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";

@Component({
  components: {
    Modal,
    CopyText,
    ExportWallet,
  },
})
export default class MnemonicPhrase extends Vue {
  isSuccess: boolean = false;

  $refs!: {
    export: ExportWallet;
    modal: Modal;
  };

  @Prop({ default: "" }) phrase!: string;

  open(): void {
    let modal = this.$refs.modal as Modal;
    modal.open();
  }

  mounted() {
    this.open();
  }

  success() {
    this.$refs.export.clear();
    this.isSuccess = true;
  }

  logout() {
    this.$store.dispatch("logout");
  }

  get allWallets(): MnemonicWallet[] {
    return this.$store.state.wallets;
  }
}
