







































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Modal from "@/components/modals/Modal.vue";
import Erc20Token from "@/js/Erc20Token";
import Big from "big.js";
import { WalletType } from "@/js/wallets/types";
import { bnToBig } from "@/helpers/helper";
import ERC721Token from "@/js/ERC721Token";
import ERC721Row from "@/components/modals/EvmTokenSelect/ERC721Row.vue";
import { ERC721WalletBalance } from "@/store/modules/assets/modules/types";
import { iErc721SelectInput } from "@/components/misc/EVMInputDropdown/types";

@Component({
  components: {
    ERC721Row,
    Modal,
  },
})
export default class EVMTokenSelectModal extends Vue {
  $refs!: {
    modal: Modal;
  };
  open(): void {
    let modal = this.$refs.modal as Modal;
    modal.open();
  }

  get tokens(): Erc20Token[] {
    let tokens: Erc20Token[] = this.$store.getters["Assets/networkErc20Tokens"];
    let filt = tokens.filter((t) => {
      if (t.balanceBN.isZero()) return false;
      return true;
    });
    return filt;
  }

  get erc721s(): ERC721Token[] {
    let w: WalletType = this.$store.state.activeWallet;
    return this.$store.getters["Assets/ERC721/networkContracts"];
  }

  // get symbol() {
  //     if (this.selected === 'native') return 'DJT'
  //     else return this.selected.data.symbol
  // }

  get djtxBalance(): Big {
    let w: WalletType | null = this.$store.state.activeWallet;
    if (!w) return Big(0);
    let balBN = w.ethBalance;
    return bnToBig(balBN, 18);
  }

  select(token: Erc20Token | "native") {
    this.$emit("select", token);
    this.close();
  }

  onERC721Select(val: iErc721SelectInput) {
    this.$emit("selectCollectible", val);
    this.close();
  }

  close() {
    this.$refs.modal.close();
  }
}
