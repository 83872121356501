var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",staticClass:"print_modal",attrs:{"title":_vm.$t('modal.print.title')}},[_c('div',{ref:"qr_body",staticClass:"qr_body"},[_c('img',{ref:"bg",style:({
        display: 'none',
        height: (_vm.height + "px"),
        width: "100%",
        // width: '100%',
        // paddingTop: `${100 / aspectRatio}%`,
      }),attrs:{"src":require("@/assets/paper_wallet/bg.png")}}),_c('canvas',{ref:"pdf",staticClass:"pdf_preview",style:({
        width: "100%",
        height: (_vm.height + "px"),
        // width: '100%',
        // paddingTop: `${100 / aspectRatio}%`,
      })}),_c('v-btn',{attrs:{"depressed":"","block":""},on:{"click":_vm.print}},[_vm._v(_vm._s(_vm.$t("modal.print.submit")))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }