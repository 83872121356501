















import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import ExportWallet from "@/components/wallet/manage/ExportWallet.vue";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";

@Component({
  components: {
    Modal,
    ExportWallet,
  },
})
export default class ExportKeys extends Vue {
  isActive: boolean = false;
  title: string = "Create Keystore File";

  $refs!: {
    modal: Modal;
    export: ExportWallet;
  };

  @Prop() wallets!: MnemonicWallet[];

  beforeClose() {
    this.$refs.export.clear();
  }

  open() {
    this.$refs.modal.open();
  }

  close() {
    this.isActive = false;
  }

  handleExportSuccess() {
    // @ts-ignore
    this.$refs.modal.close();
    this.close();
  }
}
