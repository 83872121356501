








import { Vue, Component, Watch } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import Spinner from "@/components/misc/Spinner.vue";
@Component({
  components: { Spinner, Modal },
})
export default class LedgerWalletLoading extends Vue {
  $refs!: {
    modal: Modal;
  };
  get isActive() {
    return this.$store.state.Ledger.isWalletLoading;
  }

  mounted() {
    if (this.isActive) {
      this.$refs.modal.open();
    }
  }

  @Watch("isActive", { immediate: true })
  onActive(val: boolean): void {
    if (!this.$refs.modal) return;
    if (val) {
      this.$refs.modal.open();
    } else {
      this.$refs.modal.close();
    }
  }
}
