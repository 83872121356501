







































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import AddKeyFile from "@/components/wallet/manage/AddKeyFile.vue";
import AddKeyString from "@/components/wallet/manage/AddKeyString.vue";
import AddMnemonic from "@/components/wallet/manage/AddMnemonic.vue";
interface ITab {
  id: number;
  name: string;
}

@Component({
  components: {
    Modal,
    AddKeyFile,
    AddKeyString,
    AddMnemonic,
  },
})
export default class ImportKeys extends Vue {
  title: string = "";
  selectedTab: string = "";

  $refs!: {
    modal: Modal;
    keyfile: AddKeyFile;
    keyString: AddKeyString;
    mnemonic: AddMnemonic;
  };
  created() {
    this.title = this.$t("keys.import_key_title") as string;
  }

  open() {
    this.$refs.modal.open();
    this.selectedTab = "private"; // explicitly set v-model value for modal
  }

  beforeClose() {
    this.$refs.keyfile?.clear();
    this.$refs.keyString?.clear();
    this.$refs.mnemonic?.clear();
  }

  handleImportSuccess() {
    this.$refs.modal.close();
    this.$store.dispatch("Notifications/add", {
      title: this.$t("keys.import_key_success_title"),
      message: this.$t("keys.import_key_success_msg"),
    });
  }
}
