
















import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ default: "Modal Title" }) title!: string;
  @Prop({ default: true }) can_close!: boolean;
  @Prop({ default: false }) icy!: boolean;

  isActive: boolean = false;

  public open() {
    this.isActive = true;
  }

  bgclick() {
    if (this.can_close) {
      this.close();
    }
  }

  public close() {
    this.$emit("beforeClose");
    this.isActive = false;
  }
}
