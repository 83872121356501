





















































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import { WalletType } from "@/js/wallets/types";

import {
  UTXOSet as AVMUTXOSet,
  UTXO as AVMUTXO,
  AVMConstants,
} from "avalanche/dist/apis/avm";
import {
  UTXOSet as PlatformUTXOSet,
  UTXO as PlatformUTXO,
  PlatformVMConstants,
  StakeableLockOut,
} from "avalanche/dist/apis/platformvm";
import UTXORow from "@/components/modals/UtxosBreakdown/AVMUTXORow.vue";

@Component({
  components: { UTXORow, Modal },
})
export default class UtxosBreakdownModal extends Vue {
  chain = "V";

  $refs!: {
    modal: Modal;
  };
  open(): void {
    let modal = this.$refs.modal as Modal;
    //@ts-ignore
    modal.open();
  }

  setChain(chainID: string) {
    this.chain = chainID;
  }

  get wallet(): WalletType | null {
    return this.$store.state.activeWallet;
  }

  get avmUTXOs(): AVMUTXO[] {
    if (!this.wallet) return [];
    let utxos = this.wallet.getUTXOSet().getAllUTXOs();
    let sorted = utxos.sort(this.sortFnc);
    return sorted;
  }

  get platformUTXOs(): PlatformUTXO[] {
    if (!this.wallet) return [];
    let utxos = this.wallet.getPlatformUTXOSet().getAllUTXOs();
    let sorted = utxos.sort(this.sortFnc);
    return sorted;
  }

  get isEmpty() {
    if (this.chain === "V") {
      return this.avmUTXOs.length === 0;
    } else {
      return this.platformUTXOs.length === 0;
    }
  }

  sortFnc<UTXO extends AVMUTXO | PlatformUTXO>(a: UTXO, b: UTXO) {
    let aOut = a.getOutput();
    let bOut = b.getOutput();

    let aType = aOut.getTypeID();
    let bType = bOut.getTypeID();

    if (aType === bType) {
      let aLock = aOut.getLocktime().toNumber();
      let bLock = bOut.getLocktime().toNumber();

      if (aType === PlatformVMConstants.STAKEABLELOCKOUTID) {
        let aStakeLock = (aOut as StakeableLockOut)
          .getStakeableLocktime()
          .toNumber();
        let bStakeLock = (bOut as StakeableLockOut)
          .getStakeableLocktime()
          .toNumber();

        aLock = Math.max(aLock, aStakeLock);
        bLock = Math.max(bLock, bStakeLock);
      }

      if (aLock !== bLock) return bLock - aLock;
      return 0;
    } else {
      if (aType === AVMConstants.SECPXFEROUTPUTID) {
        return -1;
      } else if (bType === AVMConstants.SECPXFEROUTPUTID) {
        return 1;
      }

      if (aType === AVMConstants.NFTXFEROUTPUTID) {
        return -1;
      } else if (bType === AVMConstants.NFTXFEROUTPUTID) {
        return 1;
      }

      if (aType === AVMConstants.NFTMINTOUTPUTID) {
        return -1;
      } else if (bType === AVMConstants.NFTMINTOUTPUTID) {
        return 1;
      }

      if (aType === AVMConstants.SECPMINTOUTPUTID) {
        return -1;
      } else if (bType === AVMConstants.SECPMINTOUTPUTID) {
        return 1;
      }

      // if(aType === AVMConstants.)
    }

    return 0;
  }
}
