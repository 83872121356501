

























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import Big from "big.js";
import { DerivationListBalanceDict } from "@/components/modals/HdDerivationList/types";
import { LedgerWallet } from "@/js/wallets/LedgerWallet";
import { WalletType } from "@/js/wallets/types";

import { ava } from "@/AVA";
import { getPreferredHRP } from "avalanche/dist/utils";
import { AVA_ACCOUNT_PATH } from "../../../js/wallets/MnemonicWallet";

@Component
export default class HdDerivationListRow extends Vue {
  @Prop() index!: number;
  @Prop() path!: number;
  @Prop() address!: string;
  @Prop() balance!: DerivationListBalanceDict;

  get cleanBalance(): DerivationListBalanceDict {
    let res: DerivationListBalanceDict = {};
    for (var bal in this.balance) {
      let balance: Big = this.balance[bal];
      if (balance.gt(Big(0))) {
        res[bal] = balance;
      }
    }
    return res;
  }

  get noBalance(): boolean {
    return Object.keys(this.cleanBalance).length === 0;
  }

  get assetsDict() {
    return this.$store.state.Assets.assetsDict;
  }

  get wallet() {
    return this.$store.state.activeWallet as WalletType;
  }

  get walletType() {
    return this.wallet.type;
  }

  async verifyLedgerAddress() {
    const wallet = this.wallet as LedgerWallet;

    let networkId = ava.getNetworkID();
    let hrp = getPreferredHRP(networkId);

    wallet.app.getWalletAddress(
      `${AVA_ACCOUNT_PATH}/${this.path}/${this.index}`,
      hrp
    );
  }
}
